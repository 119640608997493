import React, { Suspense } from "react"
import { graphql } from "gatsby"
import { kebabCase } from "lodash"
// imports without lazy loading
import ContactForm from "../sections/ContactForm"
import CustomHtml from "../sections/CustomHtml"
// dynamic imports
import Loadable from "@loadable/component"
const Article = Loadable(() => import("../sections/Article"))
const BigImageCards = Loadable(() => import("../sections/BigImageCards"))
const BlogGrid = Loadable(() => import("../sections/BlogGrid"))
const ButtonGrid = Loadable(() => import("../sections/ButtonGrid"))
const Careers = Loadable(() => import("../sections/Careers"))
const CareersAts = Loadable(() => import("../sections/CareersAts"))
const Contact = Loadable(() => import("../sections/Contact"))
const Cta = Loadable(() => import("../sections/Cta"))
const CtaInfoCards = Loadable(() => import("../sections/CtaInfoCards"))
const DynamicTitle = Loadable(() => import("../sections/DynamicTitle"))
const Faqs = Loadable(() => import("../sections/Faqs"))
const ImageCards = Loadable(() => import("../sections/ImageCards"))
const ImageCarousel = Loadable(() => import("../sections/ImageCarousel"))
const ImageGrid = Loadable(() => import("../sections/ImageGrid"))
const ImageSection = Loadable(() => import("../sections/Image"))
const ImageTextSection = Loadable(() =>
  import("../sections/ImageText/ImageTextSection")
)
const ImageTextCarousel = Loadable(() =>
  import("../sections/ImageTextCarousel/ImageTextCarousel")
)
const InfoCards = Loadable(() => import("../sections/InfoCards"))
const InteractiveInfoCards = Loadable(() =>
  import("../sections/InteractiveInfoCards")
)
const LogoCarousel = Loadable(() => import("../sections/LogoCarousel"))
const LogoGrid = Loadable(() => import("../sections/LogoGrid"))
const NewsletterSignup = Loadable(() => import("../sections/NewsletterSignup"))
const PortfolioGrid = Loadable(() => import("../sections/PortfolioGrid"))
const Pricing = Loadable(() => import("../sections/Pricing"))
const PricingGrid = Loadable(() => import("../sections/PricingGrid"))
const QuoteCarousel = Loadable(() => import("../sections/QuoteCarousel"))
const Stats = Loadable(() => import("../sections/Stats"))
const TeamGrid = Loadable(() => import("../sections/TeamGrid"))
const TeamMember = Loadable(() => import("../sections/TeamMember"))
const Text = Loadable(() => import("../sections/Text"))
const ThinCta = Loadable(() => import("../sections/ThinCta"))
const Twitter = Loadable(() => import("../sections/Twitter"))

export const getSectionId = section => {
  if (section.__component)
    return `sections.${section.__component}.${section.strapi_id}`
  if (section.__typename)
    return `sections.${kebabCase(section.__typename.slice(27))}.${
      section.strapi_id
    }`
  // slice(27) removes "STRAPI__COMPONENT_SECTIONS_"
  else return ""
}

const Sections = ({ section }) => {
  const isSSR = typeof window === "undefined"
  let RenderedSection
  if (section.section) {
    if (section.section.hidden) {
      return null
    }
    section.section.sectionId = getSectionId(section)
  }

  switch (section.__typename || section.__component) {
    case "STRAPI__COMPONENT_SECTIONS_IMAGE_TEXT": // Fallthrough
    case "sections.image-text":
      RenderedSection = <ImageTextSection {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_IMAGE": // Fallthrough
    case "sections.image":
      RenderedSection = <ImageSection {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CTA":
    case "sections.cta":
      RenderedSection = <Cta {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CTA_INFO_CARDS":
    case "sections.cta-info-cards":
      RenderedSection = <CtaInfoCards {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_INFO_CARDS":
    case "sections.info-cards":
      RenderedSection = <InfoCards {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_TWITTER":
    case "sections.twitter":
      RenderedSection = <Twitter {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_BUTTON_GRID":
    case "sections.button-grid":
      RenderedSection = <ButtonGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_BLOG_GRID":
    case "sections.blog-grid":
      RenderedSection = <BlogGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_NEWSLETTER_SIGNUP":
    case "sections.newsletter-signup":
      RenderedSection = <NewsletterSignup {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_TEAM_GRID":
    case "sections.team-grid":
      RenderedSection = <TeamGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_TEAM_MEMBER":
    case "sections.team-member":
      RenderedSection = <TeamMember {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_LOGO_GRID":
    case "sections.logo-grid":
      RenderedSection = <LogoGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_IMAGE_CAROUSEL":
    case "sections.image-carousel":
      RenderedSection = <ImageCarousel {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_STATS":
    case "sections.stats":
      RenderedSection = <Stats {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CONTACT":
    case "sections.contact":
      RenderedSection = <Contact {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CAREERS":
    case "sections.careers":
      RenderedSection = <Careers {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CAREERS_ATS":
    case "sections.careers-ats":
      RenderedSection = <CareersAts {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_PRICING":
    case "sections.pricing":
      RenderedSection = <Pricing {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_PRICING_GRID":
    case "sections.pricing-grid":
      RenderedSection = <PricingGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CONTACT_FORM":
    case "sections.contact-form":
      RenderedSection = <ContactForm {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_DYNAMIC_TITLE":
    case "sections.dynamic-title":
      RenderedSection = <DynamicTitle {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_LOGO_CAROUSEL":
    case "sections.logo-carousel":
      RenderedSection = <LogoCarousel {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CUSTOM_HTML":
    case "sections.custom-html":
      RenderedSection = <CustomHtml {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_PORTFOLIO_GRID":
    case "sections.portfolio-grid":
      RenderedSection = <PortfolioGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_ARTICLE":
    case "sections.article":
      RenderedSection = <Article {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_QUOTE_CAROUSEL":
    case "sections.quote-carousel":
      RenderedSection = <QuoteCarousel {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_IMAGE_TEXT_CAROUSEL":
    case "sections.image-text-carousel":
      RenderedSection = <ImageTextCarousel {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_FAQS":
    case "sections.faqs":
      RenderedSection = <Faqs {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_IMAGE_GRID":
    case "sections.image-grid":
      RenderedSection = <ImageGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_IMAGE_CARDS":
    case "sections.image-cards":
      RenderedSection = <ImageCards {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_BIG_IMAGE_CARDS":
    case "sections.big-image-cards":
      RenderedSection = <BigImageCards {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_INTERACTIVE_INFO_CARDS":
    case "sections.interactive-info-cards":
      RenderedSection = <InteractiveInfoCards {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_TEXT":
    case "sections.text":
      RenderedSection = <Text {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_THIN_CTA":
    case "sections.thin-cta":
      RenderedSection = <ThinCta {...section} />
      break
    default:
      return null
  }
  if (isSSR) return RenderedSection
  else return <Suspense fallback={<></>}>{RenderedSection}</Suspense>
}

export default Sections

export const query = graphql`
  fragment Sections on PageSectionsDynamicZone {
    __typename
    ... on STRAPI__COMPONENT_SECTIONS_IMAGE_TEXT {
      ...ImageTextSection
    }
    ... on STRAPI__COMPONENT_SECTIONS_IMAGE {
      ...ImageSection
    }
    ... on STRAPI__COMPONENT_SECTIONS_CTA {
      ...Cta
    }
    ... on STRAPI__COMPONENT_SECTIONS_TWITTER {
      ...Twitter
    }
    ... on STRAPI__COMPONENT_SECTIONS_INFO_CARDS {
      ...InfoCards
    }
    ... on STRAPI__COMPONENT_SECTIONS_BUTTON_GRID {
      ...ButtonGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_BLOG_GRID {
      ...BlogGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_NEWSLETTER_SIGNUP {
      ...NewsletterSignup
    }
    ... on STRAPI__COMPONENT_SECTIONS_TEAM_GRID {
      ...TeamGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_TEAM_MEMBER {
      ...TeamMember
    }
    ... on STRAPI__COMPONENT_SECTIONS_LOGO_GRID {
      ...LogoGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_IMAGE_CAROUSEL {
      ...ImageCarousel
    }
    ... on STRAPI__COMPONENT_SECTIONS_STATS {
      ...Stats
    }
    ... on STRAPI__COMPONENT_SECTIONS_CONTACT {
      ...Contact
    }
    ... on STRAPI__COMPONENT_SECTIONS_CAREERS {
      ...Careers
    }
    ... on STRAPI__COMPONENT_SECTIONS_CAREERS_ATS {
      ...CareersAts
    }
    ... on STRAPI__COMPONENT_SECTIONS_PRICING {
      ...Pricing
    }
    ... on STRAPI__COMPONENT_SECTIONS_PRICING_GRID {
      ...PricingGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_CONTACT_FORM {
      ...ContactForm
    }
    ... on STRAPI__COMPONENT_SECTIONS_DYNAMIC_TITLE {
      ...DynamicTitle
    }
    ... on STRAPI__COMPONENT_SECTIONS_LOGO_CAROUSEL {
      ...LogoCarousel
    }
    ... on STRAPI__COMPONENT_SECTIONS_CUSTOM_HTML {
      ...CustomHtml
    }
    ... on STRAPI__COMPONENT_SECTIONS_PORTFOLIO_GRID {
      ...PortfolioGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_ARTICLE {
      ...Article
    }
    ... on STRAPI__COMPONENT_SECTIONS_QUOTE_CAROUSEL {
      ...QuoteCarousel
    }
    ... on STRAPI__COMPONENT_SECTIONS_IMAGE_TEXT_CAROUSEL {
      ...ImageTextCarousel
    }
    ... on STRAPI__COMPONENT_SECTIONS_FAQS {
      ...Faqs
    }
    ... on STRAPI__COMPONENT_SECTIONS_IMAGE_GRID {
      ...ImageGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_IMAGE_CARDS {
      ...ImageCards
    }
    ... on STRAPI__COMPONENT_SECTIONS_BIG_IMAGE_CARDS {
      ...BigImageCards
    }
    ... on STRAPI__COMPONENT_SECTIONS_CTA_INFO_CARDS {
      ...CtaInfoCards
    }
    ... on STRAPI__COMPONENT_SECTIONS_INTERACTIVE_INFO_CARDS {
      ...InteractiveInfoCards
    }
    ... on STRAPI__COMPONENT_SECTIONS_TEXT {
      ...Text
    }
    ... on STRAPI__COMPONENT_SECTIONS_THIN_CTA {
      ...ThinCta
    }
  }
`
